<script>
import partnerChoices from '@/mixins/partnerChoices';
import equalizationCalculations from '@/mixins/equalizationCalculations';
import formatMoney from '@/mixins/formatMoney';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerChoices, equalizationCalculations, formatMoney],
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		equalizationPayment() {
			return this.agreementData.equalizationPayment;
			// return this.agreementData.equalizationPayment = this.agreementData.equalizationPayment.hasOwnProperty('additionalClausesToRecitals') ? this.agreementData.equalizationPayment : {...this.agreementData.equalizationPayment, additionalClausesToRecitals: []};
		},
		equalizationPaymentOptions() {
			let paymentOptions = [];
			// The first option only appears if the user included 'Family Residence' in the Matter Details;
			// The second option only appears if 'Additional Real Property' is selected in Matter Details;
			// and OTHERWISE this should default to 'Paid when the Agreement is signed'
			const propertyRecitals = (section) =>
				this.agreementData.agreement.propertyRecitals &&
				this.agreementData.agreement.propertyRecitals.length &&
				this.agreementData.agreement.propertyRecitals.includes(section);
			if (propertyRecitals('Family Residence')) {
				paymentOptions = [
					...paymentOptions,
					{
						text: 'Paid as part of the transfer or sale of the Family Residence',
						value: 'familyResidence',
					},
				];
			}
			if (propertyRecitals('Additional Real Property')) {
				paymentOptions = [
					...paymentOptions,
					{
						text: 'Paid as part of the transfer or sale of Additional Real Property',
						value: 'additionalProperty',
					},
				];
			}

			return [
				...paymentOptions,
				{ text: 'Paid when the Agreement is signed', value: 'signed' },
			];
		},

		exclusionsToBeRepaid() {
			return (
				this.propertyEqualization.spouseOneRepayExclusion > 0 ||
				this.propertyEqualization.spouseTwoRepayExclusion > 0
			);
		},
		additionalPropertyChoices() {
			let choices = this.agreementData.properties.map((property, index) => (
					{
						text: this.getAddressText(property.address),
						value: `${property.address['street']}`,
					}
			));
			if(choices.length === 1 && this.agreementData.equalizationPayment.paymentMethod === 'additionalProperty'){
				this.equalizationPayment.additionalPropertySelection = choices[0].value
			}
			return choices
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods:{
		getAddressText(address){
			 let formattedAddress = '';
			 formattedAddress = address['street'] ? `${address['street']},` : '';
			 formattedAddress += address['city'] ? `${address['city']},` : '';
			 formattedAddress += address['province'] ? `${address['province']}` : '';
			 return formattedAddress;
		}
	},
	mounted(){
		this.agreementData.equalizationPayment = this.agreementData.equalizationPayment.hasOwnProperty('additionalClausesToRecitals') ? this.agreementData.equalizationPayment : {...this.agreementData.equalizationPayment, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card v-if="!clientUserAgreement">
		<h4 slot="header" class="section-heading">Equalization Payments</h4>

		<div v-if="overallEqualization">
			Having regard to the parties’ decisions, a payment of ${{
				formatMoney(overallEqualization)
			}}
			should be paid by {{ transferredFrom }} to {{ transferredTo }} to
			equalize the distribution of property{{
				exclusionsToBeRepaid ? ' and repay any exclusions' : ''
			}}.
		</div>
		<yes-no-choice
			v-model="equalizationPayment.includeEqPayment"
			reset-undefined
			:label="`Would you like to include an equalization payment clause?`"
			class="my-3"
			:testid="'equalization-inclusion'"
			:disabled="isViewMode"
		/>
		<conditional-group
			class="my-3"
			:condition="equalizationPayment.includeEqPayment"
		>
		<yes-no-choice
			v-model="equalizationPayment.useDiviiAmount"
			reset-undefined
			:label="`Would you like to use the calculated figure of $${formatMoney(overallEqualization)}?`"
			class="my-3"
			:testid="'equalization-use-figure'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="!equalizationPayment.useDiviiAmount"
		>
			<input-group
				v-model="equalizationPayment.amount"
				reset-undefined
				class="my-3"
				dollar
				label="What is the adjusted equalization payment you would like to use?"
				:testid="'equalization-adjusted-payment'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="equalizationPayment.paymentMethod"
			class="mb-2"
			input-cols="8"
			label="How will the equalization be paid?"
			reset-undefined
			select
			:options="equalizationPaymentOptions"
			:testid="'equalization-payment-options'"
			:length="'long'"
			:disabled="isViewMode"
		/>
		<conditional-group
			class="my-3"
			:condition="equalizationPayment.paymentMethod === 'additionalProperty' && 
			additionalPropertyChoices.length>0"
		>
			<b-form-group
				id="input-additionalPropertySelection"
				input-cols="8"
				label="Select the additional property you would like to use"
				label-for="additionalPropertySelection"
			>
				<b-form-select
					id="additionalPropertySelection"
					v-model="equalizationPayment.additionalPropertySelection"
					size="sm"
					:options="additionalPropertyChoices"
					data-testid="additional-property-selection"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>
		</conditional-group>
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="equalizationPayment.additionalClausesToRecitals" :testid="'equalization-add-clause-to-recitals-'"
			:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="equalizationPayment.additionalClauses" :testid="'equalization-add-clause-'"
			:disabled="isViewMode"/>
		</conditional-group>
	</b-card>
</template>
