<script>
import { mapState } from 'vuex';
import { BIconBoxArrowUpRight,BIconChevronBarRight,BIconChevronBarLeft,BSpinner ,BIconArrowRight,BIconDownload, BIconFileEarmarkText} from 'bootstrap-vue'; //box-arrow-up-right

import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import PreviewWrapper from '@/components/PreviewWrapper.vue';
import newSummary from '@/components/newSummary.vue';

import Summary from '@/components/Agreements/Summary.vue';
import ParentalPlanner from '@/components/ParentalPlanner.vue';
import PropertySchedule from '@/components/PropertySchedule.vue';
import Collaboration from '@/components/Collaboration.vue';
import Documents from '@/components/Documents.vue';
import ChildSupportCalculations from '@/components/Agreements/AgreementChildSupport.vue';

import agreementTitle from '@/lib/agreement-title';
import { partner1Name, partner2Name } from '@/mixins/partnerNames';
import featureEnabled from '@/mixins/featureFlag';
import permissions from '@/mixins/permissions';
import storeHelper from '@/mixins/storeHelper';
import clientUserFormHelper from '@/mixins/clientUserFormHelper';

import previewSections from './agreementSections';
import { analytics } from '../firebase';
import ActiveUser from '../components/ActiveUser.vue';
import AgreementNavigation from '../components/Agreements/AgreementNavigation.vue';
import PermissionError from '../components/PermissionError.vue';
import {hasViewPermission,currentRoleLevelName,isClientUserAgreement, trialActiveForClients,hasEditPermission} from '@/mixins/roleLevelPermissions';
import emailApiRequest from '@/lib/emailApiRequest';
import SeparationFactSheet from '../components/SeparationFactSheet.vue';
import educationPanelData from './educationPanel.json'

import { validationMixin } from "vuelidate";
import { required ,requiredIf } from "vuelidate/lib/validators";
import toastMessage from '@/mixins/toast';
import SubscriptionModal from '../components/SubscriptionModal.vue';
import FinancialDocumentChecklist from './FinancialDocumentChecklist.vue';
import store from './../store';

import subsciptionHelpers from '@/mixins/subscriptionHelpers';

const sectionEnabled = (section) => (agreementData) =>
	agreementData.agreement.sections &&
	agreementData.agreement.sections.includes(section);
const propertyRecitals = (section) => (agreementData) =>
	agreementData.agreement.propertyRecitals &&
	agreementData.agreement.propertyRecitals.includes(section);
let clientUserAgreement = localStorage.getItem('clientUserAgreement') ? localStorage.getItem('clientUserAgreement') : '';

// const clientUserCheck = () => () => !clientUserAgreement

const sections = [
	{
		component: require('@/components/Agreements/MatterDetails'),
		group: 'Basics',
		id: 'matter-details',
		title: 'Matter Details',
		props: ({clientUserAgreement: clientUserAgreement}),
		if: (_)=>{
			return hasViewPermission('section-basics')
		}
	},
	{
		component: require('@/components/Agreements/Partner'),
		group: 'Basics',
		id: 'parter1-basics',
		props: ({ agreementData }) => ({ partnerData: agreementData.partner1, partner:'party1', id:'parter1-employed',agreementData:agreementData }),
		title: ({ agreementData }) => `${partner1Name(agreementData)}'s Details`,
		if: (_)=>{
			return hasViewPermission('section-basics')
		}
	},
	{
		component: require('@/components/Agreements/Partner'),
		group: 'Basics',
		id: 'parter2-basics',
		props: ({ agreementData }) => ({ partnerData: agreementData.partner2, partner:'party2', id:'parter2-employed',agreementData:agreementData }),
		title: ({ agreementData }) => `${partner2Name(agreementData)}'s Details`,
		if: (_)=>{
			if(hasViewPermission('section-other-partner-details')==false){
				return hasViewPermission('section-other-partner-details')
			}else{
				return hasViewPermission('section-basics')
			}
		}
	},
	{
		component: require('@/components/Agreements/ChildInformation'),
		group: 'Basics',
		id: 'child-information',
		if: (agreementData) =>
			some(
				[sectionEnabled('Parenting'), sectionEnabled('Child Support')],
				(check) => check(agreementData)
			) &&  hasViewPermission('section-basics'),
		// props: ({clientUserAgreement: clientUserAgreement}),
		title: 'Child Information',
	},
	{
		component: require('@/components/Agreements/Relationship'),
		group: 'Basics',
		id: 'relationship',
		// props: ({clientUserAgreement: clientUserAgreement}),
		title: 'Relationship',
		if: (_)=>{
			return hasViewPermission('section-basics')
		}
	},
	{
		component: require('@/components/Agreements/LegalHistory'),
		group: 'Basics',
		id: 'legal-history',
		// if: clientUserCheck(),
		if: (_)=>{
			return hasViewPermission('section-basics') && !trialActiveForClients()
		},
		//  && hasViewPermission('section-basics'),
		// props: ({clientUserAgreement: clientUserAgreement}),
		title: 'Legal History of Parties',
	},
	{
		component: require('@/components/Agreements/AgreementBasics'),
		group: 'Basics',
		id: 'agreement-basics',
		if: (_)=>{
			return hasViewPermission('section-basics') && !trialActiveForClients() && store.state.currentRoleLevel.roleAssociated !== 'party'
		},
		//  && hasViewPermission('section-basics'),
		title: 'Agreement Basics',
	},
	{
		component: require('@/components/Agreements/GuardianshipBasics'),
		group: 'Parenting',
		id: 'parenting-responsibilities',
		if: (agreementData)=>{
			return sectionEnabled('Parenting')(agreementData) && hasViewPermission('section-parenting') && !trialActiveForClients()
		},
		title: 'Parenting Responsibilities',
	},
	{
		component: require('@/components/Agreements/ParentingTime'),
		group: 'Parenting',
		id: 'parenting-time',
		if: (agreementData)=>{
			return sectionEnabled('Parenting')(agreementData) && hasViewPermission('section-parenting') && !trialActiveForClients()
		},
		title: 'Parenting Time',
	},
	{
		component: require('@/components/Agreements/ParentingAgreements'),
		group: 'Parenting',
		id: 'parenting-agreements',
		if: (agreementData)=>{
			return sectionEnabled('Parenting')(agreementData) && hasViewPermission('section-parenting') && !trialActiveForClients()
		},
		title: 'Parenting Agreements',
	},
	{
		component: require('@/components/Agreements/OtherParentingMatters'),
		group: 'Parenting',
		id: 'other-parenting-matters',
		if: (agreementData)=>{
			return sectionEnabled('Parenting')(agreementData) && hasViewPermission('section-parenting') && !trialActiveForClients()
		},
		title: 'Other Parenting Matters',
	},
	{
		component: require('@/components/Agreements/ChildSupportDetails'),
		group: 'Child Support',
		id: 'child-support-details',
		// if: sectionEnabled('Child Support'),
		if: (agreementData) =>{
			if(hasViewPermission('section-child-support') && (sectionEnabled('Child Support')(agreementData)) && !trialActiveForClients()){
				if(agreementData.children.length > 0 && agreementData.children.every(child => child.selfSupporting === false)){
					return agreementData.children.some(child => {
						if(child.applicableChildSupportAspects){
							return child.applicableChildSupportAspects.includes('table-amount') 
						}else{
							return false
						} 
					})
				}
				else{
					
					return true
				}
			}else{
				return false
			}
		},
		title: 'Child Support Details',
	},
	{
		component: require('@/components/Agreements/SpecialOrExtraordinaryExpenses'),
		group: 'Child Support',
		id: 'special-or-extraordinary-expenses',
		// if: sectionEnabled('Child Support'),
		if: (agreementData) =>{
			if(hasViewPermission('section-child-support') && (sectionEnabled('Child Support')(agreementData)) && !trialActiveForClients()){
				if(agreementData.children.length > 0 && agreementData.children.every(child => child.selfSupporting === false)){
					return agreementData.children.some(child => {
						if(child.applicableChildSupportAspects){
							return child.applicableChildSupportAspects.includes('special-extraordinary-expenses') 
						}else{
							return false
						} 
					})
				}
				else{
					
					return true
				}
			}else{
				return false
			}
		},
		title: 'Special or Extraordinary Expenses',
	},
	{
		component: require('@/components/Agreements/SpousalSupportDetails'),
		group: 'Spousal Support',
		id: 'spousal-support',
		// if: sectionEnabled('Spousal Support') && clientUserCheck(),
		if: (agreementData)=>{
			return sectionEnabled('Spousal Support')(agreementData) && hasViewPermission('section-spousal-support') && !trialActiveForClients()
		},
		title: 'Spousal Support Details',
	},
	{
		component: require('@/components/Agreements/MedicalCoverage'),
		group: 'Support Additionals',
		id: 'medical-coverage',
		// if: clientUserCheck(),
		if: (agreementData) =>
			some(
				[
					sectionEnabled('Spousal Support'),
					sectionEnabled('Child Support'),
				] ,
				(check) => check(agreementData)
			) && hasViewPermission('section-support-additionals') && !trialActiveForClients(),
		title: 'Medical Coverage',
	},
	{
		component: require('@/components/Agreements/SecurityForSupport'),
		group: 'Support Additionals',
		id: 'security-for-support',
		if: (agreementData) =>
			some(
				[
					sectionEnabled('Spousal Support'),
					sectionEnabled('Child Support'),
				] ,
				(check) => check(agreementData)
			) && hasViewPermission('section-support-additionals') && !trialActiveForClients(),
		title: 'Security for Support',
	},
	{
		component: require('@/components/Agreements/FamilyResidence'),
		group: 'Property',
		id: 'family-residence',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Family Residence'),
		if: (agreementData)=>{
			return propertyRecitals('Family Residence')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Family Residence',
	},
	{
		component: require('@/components/Agreements/AdditionalRealProperties'),
		group: 'Property',
		id: 'additional-real-property',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Additional Real Property'),
		if: (agreementData)=>{
			return propertyRecitals('Additional Real Property')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Additional Real Property',
	},
	{
		component: require('@/components/Agreements/Businesses'),
		group: 'Property',
		id: 'businesses',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Business'),
		if: (agreementData)=>{
			return propertyRecitals('Business')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Business',
	},
	{
		component: require('@/components/Agreements/Corporations'),
		group: 'Property',
		id: 'corporations',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Corporation'),
		if: (agreementData)=>{
			return propertyRecitals('Corporation')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Corporation',
	},
	{
		component: require('@/components/Agreements/Pensions'),
		group: 'Property',
		id: 'pensions',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Pensions'),
		if: (agreementData)=>{
			return propertyRecitals('Pensions')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Pensions',
	},
	{
		component: require('@/components/Agreements/CPP'),
		group: 'Property',
		id: 'cpp',
		// if: propertyRecitals('Canada Pension Plan') && clientUserCheck(),
		if: (agreementData)=>{
			return propertyRecitals('Canada Pension Plan')(agreementData) && hasViewPermission('section-property') && !trialActiveForClients()
		},
		title: 'CPP',
	},
	{
		component: require('@/components/Agreements/RRSP'),
		group: 'Property',
		id: 'rrsp',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('RRSPs'),
		if: (agreementData)=>{
			return propertyRecitals('RRSPs')(agreementData) && hasViewPermission('section-property')
		},
		title: 'RRSPs',
	},
	{
		component: require('@/components/Agreements/RESP'),
		group: 'Property',
		id: 'resp',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('RESPs'),
		if: (agreementData)=>{
			return propertyRecitals('RESPs')(agreementData) && hasViewPermission('section-property')
		},
		title: 'RESPs',
	},
	{
		component: require('@/components/Agreements/FinancialAccounts'),
		group: 'Property',
		id: 'financial-accounts',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Financial Accounts'),
		if: (agreementData)=>{
			return propertyRecitals('Financial Accounts')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Financial Accounts',
	},
	{
		component: require('@/components/Agreements/Vehicles'),
		group: 'Property',
		id: 'vehicles',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Vehicles'),
		if: (agreementData)=>{
			return propertyRecitals('Vehicles')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Vehicles',
	},
	{
		component: require('@/components/Agreements/OtherProperty'),
		group: 'Property',
		id: 'other-property',
		// props: ({clientUserAgreement: clientUserAgreement}),
		// if: propertyRecitals('Other Property'),
		if: (agreementData)=>{
			return  propertyRecitals('Other Property')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Other Property',
	},
	{
		component: require('@/components/Agreements/Liabilities'),
		group: 'Property',
		id: 'liabilities',
		// props: ({clientUserAgreement: clientUserAgreement}),
		if: (agreementData)=>{
			return sectionEnabled('Property')(agreementData) && hasViewPermission('section-property')
		},
		title: 'Liabilities',
	},
	{
		component: require('@/components/Agreements/EqualizationPayment'),
		group: 'Property',
		id: 'equalization-payment',
		if: (agreementData)=>{
			return  sectionEnabled('Property')(agreementData) && hasViewPermission('section-property') && !trialActiveForClients()
		},
		title: 'Equalization Payment',
	},
	{
		component: require('@/components/Agreements/Conclusions'),
		group: 'Final Clauses',
		// if: clientUserCheck(),
		if: (_)=>{
			return hasViewPermission('section-final-clauses') && !trialActiveForClients()
		},
		id: 'conclusions',
		title: 'Conclusions',
	},
];

export default {
	components: {
		BIconBoxArrowUpRight,
		'summary-view': Summary,
		'parental-planner': ParentalPlanner,
		'property-schedule': PropertySchedule,
		PreviewWrapper,
		newSummary,
		
		Collaboration,
		Documents,
		ChildSupportCalculations,
		ActiveUser,
		AgreementNavigation,
		BIconChevronBarLeft,
		BIconChevronBarRight,
		BSpinner,
		BIconArrowRight,
		BIconDownload,
		BIconFileEarmarkText,
		SeparationFactSheet,
		PermissionError,
		SubscriptionModal,
		FinancialDocumentChecklist
	},
	mixins: [featureEnabled, permissions,storeHelper,clientUserFormHelper,validationMixin,toastMessage,subsciptionHelpers],
	computed: {
		...mapState(['agreement','showPermissionDeniedPage','user','currentAgreementRoleLevelName','showAgreementTrialExpired','currentRoleLevel','editedAgreement']),
		agreementSections() {
			let allSections = sections;
			if(isClientUserAgreement()){
				const sectionsToExclude = ['legal-history', 'cpp','agreement-basics','equalization-payment'];
				// console.log('allSections before-> ',allSections)
				allSections = sections.filter(obj => !sectionsToExclude.includes(obj.id));
				// console.log('allSections after-> ',allSections)
				
			}
			return this.availableSections(allSections).map((section) => ({
				...section,
				component: section.component.default,
				props:
					typeof section.props === 'function'
						? section.props({
								agreementData: this.agreementData,
						  })
						: section.props,
				title:
					typeof section.title === 'function'
						? section.title({
								agreementData: this.agreementData,
						  })
						: section.title,
			}));
		},
		agreementGroups() {
			const groups = uniq(this.agreementSections.map((s) => s.group));
			const reArrangedGroups  = groups.map((g) => ({
				title: g,
				sections: filter(this.agreementSections, (s) => s.group === g),
			}));

			// const lastGroup = reArrangedGroups[reArrangedGroups.length - 1];
			// if(lastGroup?.sections?.length)
			// this.lastSection = lastGroup.sections[lastGroup.sections.length - 1].id;

			const sectionsToAdd = ['Spousal Support','Child Support','Parenting'];

			sectionsToAdd.forEach(sectionTitle => {
				if (!reArrangedGroups.some(group => group.title === sectionTitle) && 
					this.agreementData?.agreement?.sections?.includes(sectionTitle)) {
					
					const newGroup = {
						title: sectionTitle,
					};

					reArrangedGroups.splice(1, 0, newGroup);
				}
			});

			return reArrangedGroups;
		},
		agreementPreviewGroups() {
			console.log("pension-test")
			let agreementPreviewSections = this.availableSections(
				previewSections
			).map((section) => ({
				...section,
				title:
					typeof section.title === 'function'
						? section.title({
								agreementData: this.agreementData,
						  })
						: section.title,
			}));
			let groups = uniq(agreementPreviewSections.map((s) => s.group));
			agreementPreviewSections = this.filterAgreementPreviewSections(agreementPreviewSections);
			return groups.map((g) => ({
				title: g,
				sections: filter(
					agreementPreviewSections,
					(s) => s.group === g
				),
			}));
		},
		editorDisabled() {
			return this.agreementData.meta && !this.agreementData.meta.editing;
		},
		editorEnabled() {
			return this.agreementData.meta && this.agreementData.meta.editing;
		},
		summaryDisabled(){
			return this.agreementData.meta && !this.agreementData.meta.summaryPageActivated
		},
		tabs() {
			// console.log('builder==>',this.hasViewPermission('builder'))
			return [
				// {
				// 	id: 'summary',
				// 	visible: this.featureEnabled('feature_summary'),
				// },
				{
					id: 'builder',
					visible: true,//hasViewPermission('builder') ? true : false,
					active: true,
					imgSrc: require('@/assets/builder.svg'),
					title: 'Builder'
				},
				{
					id: 'parental-planner',
					// visible: this.sectionEnabled('Parenting'),
					visible: hasViewPermission('parenting-planner') ? true : false,
					active: this.sectionEnabled('Parenting'),
					imgSrc: require('@/assets/calendar.svg'),
					title: 'Parenting Time Planner'
				},
				{
					id: 'property-schedule',
					// visible: this.sectionEnabled('Property'),
					visible: hasViewPermission('property-schedule') ? true : false,
					active: this.sectionEnabled('Property'),
					imgSrc: require('@/assets/property.svg'),
					title: 'Property Schedule'
				},
				{
					id: 'separation-fact-sheet',
					visible: hasViewPermission('separation-fact-sheet'),
					active: !this.$v.agreementData.$anyError,
					imgSrc: require('@/assets/fact-sheet.svg'),
					title: 'Separation fact sheet'
				},
				{
					id: 'financialDocumentChecklist',
					visible: hasViewPermission('financial-document-checklist'),
					active:  (this.agreementData?.agreement?.sections?.includes('Parenting') ?  this.agreementData?.agreement?.sections.length> 1 : this.agreementData?.agreement?.sections.length> 0),
					imgSrc: require('@/assets/FinancialDocumentChecklist.svg'),
					title: 'Financial Document Checklist'
				},
				{
					id: 'preview',
					visible: hasViewPermission('preview') ? true : false,
					active: !this.$v.agreementData.$anyError,
					// active: true,
					imgSrc: require('@/assets/preview.svg'),
					title: 'Preview'
				},
				{
					id: 'summary',
					// visible: this.editorEnabled,
					visible: !this.trialActiveForClients() && hasViewPermission('summary-page'),
					active: !this.$v.agreementData.$anyError,
					imgSrc: require('@/assets/summary.svg'),
					title: 'Summary'
				},
				{
					id: 'editor',
					// visible: this.editorEnabled,
					visible: hasViewPermission('editor') ? true : false,
					active: trialActiveForClients() ? true :  !this.$v.agreementData.$anyError && (this.editedAgreement ? true : this.agreementData.isSummaryOpened == true),
					imgSrc: require('@/assets/edit.svg'),
					title: 'Editor'
				},
				// {
				// 	id: 'childSupport',
				// 	visible: this.sectionEnabled('Parenting'),
				// }
			].filter(({ visible }) => visible);
		},
		isMobile(){
			return window.innerWidth < 768
		},
		showPermissionDeniedPageDisplay(){
			// return this.getClientOrPreviouslyLoggedId && this.showPermissionDeniedPage;
			return this.showPermissionDeniedPage;
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		},
		isClientUserAgreementComputed(){
			return isClientUserAgreement();
		},
		educationPanelData(){			
			return educationPanelData.filter(item => {
				if (item.section === null) {
					return true; 
				}
				if (this.tabs.some(tab => tab.id === item.section && tab.active)) {
					return true; 
				}
				if (this.agreementData?.agreement?.sections?.includes(item.section)) {
					return true; 
				}
				if (this.agreementData?.agreement?.propertyRecitals?.includes(item.section)) {
					return true;
				}
				return false; 
			});
		},
		isSummaryViewable(){
			return !this.agreementData?.meta?.summaryPageActivated && this.hasViewPermission('summary-page')
		},
		sectionsWithRequiredFields() {
			return [
				{ key: 'partner1', url: 'parter1-basics' },
				{ key: 'partner2', url: 'parter2-basics' },
				{ key: 'children', url: 'child-information' },
				{ key: 'relationship', url: 'relationship' },
			]
		}
	},
	props: ['id'],
	data() {
		return {
			agreementData: false,
			baseData: require('@/lib/agreement/base.json'),
			loadedAgreementData: false,
			tabIndex: 0, //parseInt(this.getSessionStorageString('tabIndex')) || 0,
			activeTab: this.getSessionStorageString('activeTab') || 'builder',
			activeIndex: parseInt(this.getSessionStorageString('activeIndex') || 0),
			currentSection: null,
			showSubSections:true,
			isEditorViewable: clientUserAgreement ? false : hasViewPermission('editor'),
			isEndOfAgreementPosition:false,
			isSubmitFormDisabled:false,
			showPermissionDeniedMessage: false,
			isEditorViewable: clientUserAgreement ? false : hasViewPermission('editor'),
			isEdPanelOpen: false,
			edModalData: {},
			currentRoleLevelName: currentRoleLevelName,
			summaryPageCheck: localStorage.getItem('summaryPageCheck') ? true: false,
			showSubscriptionModal: false,
			isScrollingWithTabChange: false,
			isDownloadAgreementAllowed: hasEditPermission('editor')
			// clientUserAgreementId: null,
		};
	},
	methods: {
		hasViewPermission,
		agreementTitle,
		// checkBasicRequirements(){
		// 	if(this.agreementData && 
		// 		this.agreementData.agreement && this.agreementData.agreement.updatedProvinceOfAgreement  && this.agreementData.agreement.year &&
		// 		this.agreementData.partner1.birthdate && this.agreementData.partner1.pronouns && this.agreementData.partner1.name.first && 
		// 		this.agreementData.partner1.name.last && this.agreementData.partner1.address.city && this.agreementData.partner1.address.street &&
		// 		this.agreementData.partner1.address.province && this.agreementData.partner2.birthdate && this.agreementData.partner2.pronouns && 
		// 		this.agreementData.partner2.name.first && this.agreementData.partner2.name.last && this.agreementData.partner2.address.city && 
		// 		this.agreementData.partner2.address.street && this.agreementData.partner2.address.province){
		// 			if(this.agreementData.relationship){
		// 				if(this.agreementData.relationship.status== "Married"){
		// 					return (this.agreementData.relationship.cohabitationDate && this.agreementData.relationship.livingTogether && this.agreementData.relationship.marriageDate &&
		// 						this.agreementData.relationship.marriageLocation && this.agreementData.relationship.separationDate)
		// 				}else if(this.agreementData.relationship.status== "Cohabited for over two years" || 
		// 						this.agreementData.relationship.status=="Cohabited for under two years without children"){
		// 					return (this.agreementData.relationship.cohabitationDate && this.agreementData.relationship.livingTogether && this.agreementData.relationship.separationDate)
		// 				}else if(this.agreementData.relationship.status=="Cohabited for under two years with Children"){
		// 					return true
		// 				}else{
		// 					return false
		// 				}
		// 			}
		// 			if(this.agreementData.children.length !== 0){
		// 				for(const child of this.agreementData.children){
		// 					if(child.name && child.name.first!='' && child.name.last!='' && child.name.pronouns!='' && child.name.birthdate!=null){
		// 						return true
		// 					}else{
		// 						return false
		// 					}
		// 				}
		// 			}
		// 		return true
		// 	}else{
		// 		return false
		// 	}
			 
		// },	
		availableSections(sections) {
			return this.agreementData && sections
				? sections.filter((x) =>
						x.if ? x.if(this.agreementData) : true
				  )
				: sections.filter((x) => (x.if ? false : true));
		},
		saveAgreement() {
			this.$store.dispatch('updateAgreement', {
				...this.agreementData,
				id: this.agreement.id,
				title: agreementTitle(this.agreementData),
			});
		},
		loadAgreementData() {
			let id = this.$route.params.id ? {id: this.$route.params.id} : {};
			this.$store
				.dispatch('fetchAgreement', id)
				.then(() => {
					if (location.hash) location.href = location.hash;
				});
		},
		edit() {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[
					newElement(
						'p',
						{},
						'Turning on the Editor disconnects it from the Builder, so changes in the Builder will appear in Preview but won’t update the Editor.'
					),
					newElement(
						'p',
						{},
						"Before switching, review the Agreement in Preview to ensure it's complete and accurate."
					),
					newElement(
						'p',
						{},
						"After enabling the Editor, you can still make changes in the Builder and use the 'Update' button to refresh the edited document (this will overwrite edits). Alternatively, you can update the Builder and manually copy the updated clauses into the Editor."
					),
				]
			);
			this.$bvModal
				.msgBoxConfirm([messageConent], {
					title: 'Heads Up!',
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'primary',
					cancelVariant:'pastel-blue',
					okTitle: 'I Understand',
					cancelTitle: "I'm not ready",
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then((accepted) => {
					if (accepted) {
						const meta = {
							...this.agreementData.meta,
							editing: true,
						};
						// This is mutating state please don't copy this behaviour if possible.
						this.agreementData.meta = meta;
						this.activeTab = 'editor';
						for(let index=0; index<this.tabs.length; index++){
							if(this.tabs[index].id=='editor'){
								this.activeIndex = index;
							}
						}
						analytics.logEvent('enable_agreement_edit');
					}
				})
				.catch(() => {});
		},
		viewSummary() {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[
					newElement(
						'p',
						{},
						'The Agreement Summary page will highlight the terms of the proposed Agreement.  It is wise to review this with your spouse before you make enable the Editor.  If your spouse disagrees with any portion of the proposed Agreement, they may make counterproposals and ask questions using comments and track changes in the Agreement Summary until the terms are agreed to.'
					),
					newElement(
						'p',
						{},
						"Once you have agreed on the terms of the Agreement, make any necessary updates in the Builder.  Then you may refine the language within the Editor."
					),
				]
			);
			this.$bvModal
				.msgBoxConfirm([messageConent], {
					title: 'Heads Up!',
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: 'Go To Summary',
					// cancelTitle: "I'm not ready",
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
					cancelDisabled: true,
					okOnly: true,
				})
				.then((accepted) => {
					if (accepted) {
						
					if(this.activeTab === 'builder'){
						this.$v.agreementData.$touch();
						this.$refs['matter-details'][0].triggerValidation();
						this.$refs['parter1-basics'][0].triggerValidation();
						this.$refs['parter2-basics'][0].triggerValidation();
						this.$refs['relationship'][0].triggerValidation();
						if(this.agreementData.agreement.sections.includes('Child Support') && this.agreementData.children.length > 0){
							this.$refs['child-information'][0].triggerValidation();
						}
																		
						const childInformationDetails = this.$v?.agreementData?.['children']?.$each;
						const childIndicesWithError = 
							Object.keys(childInformationDetails?.$iter)?.filter((item) => 
								childInformationDetails?.[`${item}`]?.$invalid
							);
						// Navigates to the module in which required field is not filled.
						if(this.$refs['matter-details']?.[0].$v?.$invalid) {
							this.scrollTo('#matter-details');
						}
						else {
							const sectionsWithError = this.sectionsWithRequiredFields.filter(section => {
								if(this.$v?.agreementData[`${section.key}`]?.$invalid) {
									return section.url;
								}
							});
							if(sectionsWithError?.length) {
								// Handles navigation to child information section with error if have multiple children
								if(sectionsWithError[0]?.key === 'children') {
									this.scrollTo(`#${sectionsWithError[0]?.url}-${Number(childIndicesWithError?.[0]) + 1}`)
								}
								else {
									this.scrollTo(`#${sectionsWithError[0]?.url}`)
								}
							}
						}

						if (this.$v.agreementData.$anyError) {
							this.warningMessage('', 'Please fill all the required fields to continue');
							return
						}
					}
						const meta = {
							...this.agreementData.meta,
							summaryPageActivated: true
						};
						// This is mutating state please don't copy this behaviour if possible.
						this.agreementData.meta = meta;
						this.activeTab = 'summary';
						for(let index=0; index<this.tabs.length; index++){
							if(this.tabs[index].id=='summary'){
								this.activeIndex = index;
							}
						}
						analytics.logEvent('enable_agreement_summary');
					}
				})
				.catch(() => {});
		},
		scrollTo(hash) {
			this.isScrollingWithTabChange = true; 
			this.$router.push(hash)
				.then(() => {
					this.$nextTick(() => {
						const element = document.querySelector(hash);
						if (element) {
							element.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}
						setTimeout(() => {
                            this.isScrollingWithTabChange = false;
                        }, 1000);
					});
				})
				.catch(err => {
					if (err.name !== 'NavigationDuplicated') {
						throw err;
					}
					this.$nextTick(() => {
						const element = document.querySelector(hash);
						if (element) {
							element.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}
						setTimeout(() => {
                            this.isScrollingWithTabChange = false;
                        }, 1000);
					});
				});
		},
		sectionEnabled(section) {
			if(section === 'Parenting'){
				return (
					this.agreementData?.children?.some(child => !child.selfSupporting) === true &&
					this.agreementData?.agreement?.sections?.includes(section)
				);
			}else{
				return(
				this.agreementData &&
				this.agreementData.agreement &&
				this.agreementData.agreement.sections &&
				this.agreementData.agreement.sections.length &&
				this.agreementData.agreement.sections.includes(section)
				)
			}
		},
		tabChanged(tabIndex) {
			if (trialActiveForClients() && (this.tabs[tabIndex].id === 'parental-planner' || this.tabs[tabIndex].id === 'editor')) {
                this.showSubscriptionModal = true
                return
            }
			const tabId = this.tabs[tabIndex].id;
			if (['editor', 'preview'].includes(tabId)) {
				setTimeout(() => {
					const ckEditor = document.querySelectorAll('.ck-editor');
					ckEditor.forEach(element => {
						element.removeEventListener('scroll', this.updateHash);
						element.addEventListener('scroll', this.updateHash);
					});
				}, 500);
			}
			this.summaryPageCheck=localStorage.getItem('summaryPageCheck')
			// if((this.tabs[tabIndex].id=='editor' || this.tabs[tabIndex].id=='preview' || this.tabs[tabIndex].id=='summary') && this.activeTab === 'builder'){
			// 	this.$v.agreementData.$touch();
			// 	this.$refs['matter-details'][0].triggerValidation();
			// 	this.$refs['parter1-basics'][0].triggerValidation();
			// 	this.$refs['parter2-basics'][0].triggerValidation();
			// 	this.$refs['relationship'][0].triggerValidation();
			// 	if(this.agreementData.children.length > 0){
			// 		this.$refs['child-information'][0].triggerValidation();
			// 	}
			// 	if (this.$v.agreementData.$anyError) {
			// 		this.warningMessage('', 'Please fill all the required fields to continue');
			// 		return
			// 	}
			// }
			if((this.tabs[tabIndex].id=='editor' || this.tabs[tabIndex].id=='preview' || this.tabs[tabIndex].id=='summary' || this.tabs[tabIndex].id=='separation-fact-sheet')){
				this.$v.agreementData.$touch();
				if(this.activeTab === 'builder'){
					this.$refs['matter-details'][0].triggerValidation();
					this.$refs['parter1-basics'][0].triggerValidation();
					this.$refs['parter2-basics'][0].triggerValidation();
					this.$refs['relationship'][0].triggerValidation();
					if(this.agreementData.agreement.sections.includes('Child Support') && this.agreementData.children.length > 0){
						this.$refs['child-information'][0].triggerValidation();
					}

					const childInformationDetails = this.$v?.agreementData?.['children']?.$each;
					const childIndicesWithError = 
						Object.keys(childInformationDetails?.$iter)?.filter((item) => 
							childInformationDetails?.[`${item}`]?.$invalid
						);
					// Navigates to the module in which required field is not filled.
					if(this.$refs['matter-details']?.[0].$v?.$invalid) {
						this.scrollTo('#matter-details');
					}
					else {
						const sectionsWithError = this.sectionsWithRequiredFields.filter(section => {
							if(this.$v?.agreementData[`${section.key}`]?.$invalid) {
								return section.url;
							}
						});
						if(sectionsWithError?.length) {
							// Handles navigation to child information section with error if have multiple children
							if(sectionsWithError[0]?.key === 'children') {
								this.scrollTo(`#${sectionsWithError[0]?.url}-${Number(childIndicesWithError?.[0]) + 1}`);
							}
							else {
								this.scrollTo(`#${sectionsWithError[0]?.url}`);
							}
						}
					}
				}

				if (this.$v.agreementData.$anyError) {
					this.warningMessage('', 'Please fill all the required fields to continue');
					return
				}
			}
			if(this.tabs[tabIndex].id=='summary' && !this.agreementData.meta.summaryPageActivated){
				this.viewSummary()
			}
			else if(this.tabs[tabIndex].id=='editor' && (this.agreementData.meta && !this.agreementData.meta.editing)){
				this.edit();
			}else{
			// window.sessionStorage.setItem('activeTab', this.tabs[tabIndex].id);
			this.setSessionStorageString('activeTab', this.tabs[tabIndex].id)
			this.activeTab=this.getSessionStorageString('activeTab');
			// window.sessionStorage.setItem('activeIndex', tabIndex);
			this.setSessionStorageString('activeIndex', tabIndex)
			this.activeIndex=this.getSessionStorageString('activeIndex');
			};
		},
		filterAgreementPreviewSections(previewSections){
			let updatedPreviewSections = [...previewSections];
			if(this.activeTab == 'preview' || this.activeTab =='editor'){
				if (this.agreementData.supportAdditionals && !((this.agreementData.supportAdditionals.additionalClausesMedicalCoverage && this.agreementData.supportAdditionals.additionalClausesMedicalCoverage.length > 0) || (this.agreementData.supportAdditionals.medicalCoveragePartner1 == true) || ( this.agreementData.supportAdditionals.medicalCoveragePartner2 == true))){
					let medicalCoverageIndex  = updatedPreviewSections.findIndex(section =>  section.id == 'medical-coverage');
					// console.log('medicalCoverageIndex',medicalCoverageIndex);
					if(medicalCoverageIndex > -1){
						updatedPreviewSections.splice(medicalCoverageIndex, 1);
					}
				}
			}
			return updatedPreviewSections;
		},
		selectedIcon(){
			this.activeIndex= this.getSessionStorageString('activeIndex');
		},
		updateHash() {
			if (this.isScrollingWithTabChange) return;
			const sections = document.querySelectorAll('.sections')
				for (let i = 0; i < sections.length; i++) {
					const section = sections[i]
					const rect = section.getBoundingClientRect()
					if (rect.top >= 0 && rect.top <= window.innerHeight) {
						const hash = '#' + section.id
						this.currentSection = section.id
						if (window.location.hash !== hash) {
							window.history.replaceState(null, null, hash)
						}
						break
					}
				}
		},
		goToBuilderSection(section) {
			const element = document.getElementById(section.id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				setTimeout(() => {
					window.location.hash = `#${section.id}`;
				}, 500);
			}
		},
		goToPreviewSection(section){
			const element = document.getElementById(`${section.id}-preview`);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth'});
				setTimeout(() => {
					window.location.hash = `#${section.id}-preview`
				}, 500);
			}
			else if(trialActiveForClients()){
				let previewPaywall = document.getElementById('preview-paywall');
				if (previewPaywall) {
					previewPaywall.scrollIntoView({ behavior: 'smooth'});
				}
			}
		},
		scrollToSection() {
			const sectionId = window.location.hash.slice(1);
			if (sectionId) {
				const element = document.getElementById(sectionId);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		goToSelectedSection(activeTab){
			setTimeout(() => {
				const hash = window.location.hash.slice(1);
				let id = hash;

				const suffixMap = {
					preview: '-preview',
					editor: '-preview',
					summary: '-summary'
				};

				if (activeTab === 'builder') {
					if (hash.includes('-preview') || hash.includes('-summary')) {
						id = hash.slice(0, -8);
					}
				} else {
					const suffix = suffixMap[activeTab];
					id = hash.includes(suffix) ? hash : `${hash}${suffix}`;
				}
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
					setTimeout(() => {
						window.location.hash = `#${id}`;
					}, 500);
				}
			}, 300);
		},
		handleAgreementScroll() {
			const scrollable = this.$refs.scrollable;
			if (scrollable && ((scrollable.scrollTop + scrollable.clientHeight +600) >= scrollable.scrollHeight)) {
				this.isEndOfAgreementPosition = true
			}
		},
		submitFormModal(){
			this.$bvModal.msgBoxConfirm('Submitting this form will send a notification to your lawyer.',
						{
							title: 'Submit Information Form?',
							size: 'sm',
							okVariant: 'navy',
							okTitle: 'Submit Agreement',
							cancelTitle: 'Cancel',
							footerClass: 'p-2',
							hideHeaderClose: true,
							centered: true
						})
						.then(async (value) => {
							console.log('submitted',value)
							if(value){
								let body={
									agreementId: this.agreement.id,
                    				host: window.location.host,
									clientEmail: this.user.email,
									clientName: this.user.displayName,
									uid: this.user.uid
								}
							console.log('body',body);
							this.isSubmitFormDisabled = true;
							const emailReq =  await emailApiRequest('POST','/clientFormSubmitEmail',body).catch((err)=> console.log('clientFormSubmitEmail err->',err));
							console.log('emailReq',emailReq)
								if(emailReq){
									this.isSubmitFormDisabled = false;
									//removed logout after submission
									// const currentUserRole = this.currentRoleLevel.roleAssociated 
									// if(emailReq?.status == 200){
									// 	this.$store.dispatch('logout').then(() => {
									// 		if(currentUserRole === 'party'){
									// 			this.$router.push({ path: '/couple/auth' });
									// 		}
									// 		else{
									// 			this.$router.push({ path: '/auth' });
									// 		}
									// 		this.$store.commit('setLoggingOut', false);

									// 	}).catch((err)=>{
									// 		console.log('logout catch',err)
									// 	});
									// }
								}
							}
						})
						.catch(err => {
							this.isSubmitFormDisabled = false;
							console.log('cancelled',err)
							// An error occurred
						})
		},
		handleToggleEdPanel(){
			this.isEdPanelOpen = !this.isEdPanelOpen
		},
		getVideoThumbnail(videoUrl) {
			const videoId = this.getVideoId(videoUrl)
			return `https://img.youtube.com/vi/${videoId}/0.jpg`;
		},
		showEdModal(data){
			this.edModalData = data
			if(this.edModalData.type === 'video'){
				this.edModalData.videoId = this.getVideoId(this.edModalData.url)
			}
			this.$refs['ed-modal'].show()
		},
		getVideoId(url){
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);
			return match && match[2].length === 11 ? match[2] : undefined;
		},
		downloadEdFile(url ,type){
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					const blobUrl = URL.createObjectURL(blob);
					const downloadLink = document.createElement('a');
					downloadLink.href = blobUrl;
					if(type ==='image'){
						downloadLink.download = 'image.jpeg';
					}
					else if(type === 'document'){
						downloadLink.download = 'document.pdf';
					}
					else{
						return
					}
					downloadLink.click();
					URL.revokeObjectURL(blobUrl);
				});
		},
		redirectToComponent(redirectUrl){
			const foundIndex = this.tabs.findIndex(obj => obj.id === redirectUrl);
			this.$refs['ed-modal'].hide()
			this.isEdPanelOpen = false
			if (foundIndex !== -1) {
				this.tabChanged(foundIndex); 
				return;
			}
			else{
				this.tabChanged(0);
				setTimeout(() => {
					window.location.hash = redirectUrl; 
				}, 200);
			}
		},
		trialActiveForClients,
		trialDisabledTabs(tab){
			if (trialActiveForClients() && (tab === 'editor')) {
				return true
			}
			else{
				return false
			}
		},
		openEditor(){
			this.agreementData.isSummaryOpened = true
			const editorIndex = this.tabs.findIndex(tab => tab.id === 'editor');
			if (editorIndex !== -1) {
				this.tabChanged(editorIndex);
			}
		}
	},
	watch: {
		agreement(val) {
			this.loadedAgreementData = val;
		},
		loadedAgreementData() {
			this.agreementData = {
				...this.baseData,
				...this.loadedAgreementData,
			};
			const agreementData = this.agreementData;
				const partner1 = agreementData?.partner1;
				if (!partner1.hasOwnProperty('name')) {
					this.$set(partner1, 'name', {});
				}
				if (!partner1.name.hasOwnProperty('first')) {
					this.$set(partner1.name, 'first', '');
				}
				if (!partner1.name.hasOwnProperty('last')) {
					this.$set(partner1.name, 'last', '');
				}
				if (!partner1.hasOwnProperty('birthdate')) {
					this.$set(partner1, 'birthdate', '');
				}
				if (!partner1.hasOwnProperty('pronouns')) {
					this.$set(partner1, 'pronouns', null);
				}
				if (!partner1.hasOwnProperty('address')) {
					this.$set(partner1, 'address', {});
				}
				if (!partner1.address.hasOwnProperty('street')) {
					this.$set(partner1.address, 'street', '');
				}
				if (!partner1.address.hasOwnProperty('province')) {
					this.$set(partner1.address, 'province', '');
				}
				const partner2 = agreementData?.partner2;
				if (!partner2.hasOwnProperty('name')) {
					this.$set(partner2, 'name', {});
				}
				if (!partner2.name.hasOwnProperty('first')) {
					this.$set(partner2.name, 'first', '');
				}
				if (!partner2.name.hasOwnProperty('last')) {
					this.$set(partner2.name, 'last', '');
				}
				if (!partner2.hasOwnProperty('birthdate')) {
					this.$set(partner2, 'birthdate', '');
				}
				if (!partner2.hasOwnProperty('pronouns')) {
					this.$set(partner2, 'pronouns', null);
				}
				if (!partner2.hasOwnProperty('address')) {
					this.$set(partner2, 'address', {});
				}
				if (!partner2.address.hasOwnProperty('street')) {
					this.$set(partner2.address, 'street', '');
				}
				if (!partner2.address.hasOwnProperty('province')) {
					this.$set(partner2.address, 'province', '');
				}

				if (!agreementData.agreement.hasOwnProperty('updatedProvinceOfAgreement')) {
					this.$set(agreementData.agreement, 'updatedProvinceOfAgreement', null);
				}
				
				if (!agreementData.agreement.hasOwnProperty('year')) {
					this.$set(agreementData.agreement, 'year', null);
				}
				const children = agreementData?.children;
				if (!Array.isArray(children)) {
					this.$set(agreementData, 'children', []);
				}
				children.forEach((child) => {
					if (!child.hasOwnProperty('name')) {
						this.$set(child, 'name', {});
					}
					if (!child.name.hasOwnProperty('first')) {
						this.$set(child.name, 'first', null);
					}
					if (!child.name.hasOwnProperty('last')) {
						this.$set(child.name, 'last', null);
					}
					if (!child.hasOwnProperty('birthdate')) {
						this.$set(child, 'birthdate', null);
					}
					if (!child.hasOwnProperty('pronouns')) {
						this.$set(child, 'pronouns', null);
					}
				});

				const relationship = agreementData?.relationship;
				if (!relationship.hasOwnProperty('status')) {
					this.$set(relationship, 'status', null);
				}
				if (!relationship.hasOwnProperty('cohabitationDate')) {
					this.$set(relationship, 'cohabitationDate', null);
				}
				if (!relationship.hasOwnProperty('marriageDate')) {
					this.$set(relationship, 'marriageDate', null);
				}
				if (!relationship.hasOwnProperty('marriageLocation')) {
					this.$set(relationship, 'marriageLocation', '');
				}
				if (!relationship.hasOwnProperty('separationDate')) {
					this.$set(relationship, 'separationDate', null);
				}
				if (!relationship.hasOwnProperty('livingTogether')) {
					this.$set(relationship, 'livingTogether', null);
				}
				if(!agreementData.agreement.type && this.currentRoleLevel.roleAssociated === 'party'){
					agreementData.agreement.type = "Final"
				}
		},
		agreementData: {
			handler: debounce(function () {
				this.saveAgreement();
			}, 1000),
			deep: true,
		},
		$route: {
			immediate: true,
			handler() {
				this.currentSection = window.location.hash.substring(1)
			}
		}
		
	},
    mounted() {
		// Dynamically load the Usetiful script
		(function(w, d, s) {
			var a = d.getElementsByTagName('head')[0];
			var r = d.createElement('script');
			r.async = 1;
			r.src = s;
			r.setAttribute('id', 'usetifulScript');
			r.dataset.token = "06364e7356a55a907a4e6686ef2e1d42";
			a.appendChild(r);
		})(window, document, "https://www.usetiful.com/dist/usetiful.js");

		// Dynamically load the HubSpot Chat Widget script
		// Added only for testing in staging
		// (function() {
		// 	var script = document.createElement('script');
		// 	script.type = 'text/javascript';
		// 	script.id = 'hs-script-loader';
		// 	script.async = true;
		// 	script.defer = true;
		// 	script.src = '//js-na1.hs-scripts.com/45616986.js';
		// 	document.getElementsByTagName('head')[0].appendChild(script);
		// })();
		// this.showPermissionDeniedMessage = this.showPermissionDeniedPage;
		if(this.getSessionStorageString('activeIndex')){
			this.activeIndex = this.getSessionStorageString('activeIndex');
		}else{
			this.activeIndex = 0;
		}
		// localStorage.setItem("clientUserAgreement", "bEvtNJM4ZVD6wHNhkGKG");
		// if(localStorage.getItem('clientUserAgreement')){
		// 	this.clientUserAgreementId = localStorage.getItem('clientUserAgreement')
		// }
		this.loadAgreementData();

		setTimeout(() => {
			console.log("test:showPermissionDeniedPage:", this.showPermissionDeniedPage)
			this.scrollToSection();
		}, 3000);
		setTimeout(() => {
			this.isEdPanelOpen = !this.isMobile
		}, 5000);
		
		// commented to fix the issue https://app.asana.com/0/0/1206480338726026/1208935627688452/f
		// this.$store.dispatch('fetchEditedAgreement', {
		// 	id: this.$route.params.id,
		// });

		const customScrollElements = document.querySelectorAll('.custom-scroll');
		customScrollElements.forEach(element => {
			element.addEventListener('scroll', this.updateHash);
		});
		if (this.activeTab === 'preview' || this.activeTab === 'editor') {
			setTimeout(() => {
				const ckEditor = document.querySelectorAll('.ck-editor');
				ckEditor.forEach(element => {
					element.addEventListener('scroll', this.updateHash);
				});
			}, 2000);
			
		}

		window.addEventListener('wheel', this.updateHash);

	},
	beforeDestroy() {
		const customScrollElements = document.querySelectorAll('.custom-scroll');
		customScrollElements.forEach(element => {
			element.removeEventListener('scroll', this.updateHash);
		});
		const ckEditor = document.querySelectorAll('.ck-editor');
		ckEditor.forEach(element => {
			element.removeEventListener('scroll', this.updateHash); 
		});	
		window.removeEventListener('wheel', this.updateHash)
	},
	validations:{
		agreementData:{
			partner1:{
				name:{
					first:{
						required
					},
					last:{
						required
					}
				},
				birthdate:{
					required
				},
				pronouns:{
					required
				},
				address:{
					street:{
						required
					},
					province:{
						required
					}
				}
			},
			partner2:{
				name:{
					first:{
						required
					},
					last:{
						required
					}
				},
				birthdate:{
					required
				},
				pronouns:{
					required
				},
				address:{
					street:{
						required
					},
					province:{
						required
					}
				}
				
			},
			agreement:{
				updatedProvinceOfAgreement:{
					required
				},
				year:{
					required
				}
			},
			children:{
				$each: {
					name: { 
						first:{
							required: requiredIf(function () {
								return this.agreementData.children.length > 0
							})
						},
						last:{
							required: requiredIf(function () {
								return this.agreementData.children.length > 0
							})
						}
					}, 
					birthdate:{
						required: requiredIf(function () {
							return this.agreementData.children.length > 0
						})
					},
					pronouns:{
						required: requiredIf(function () {
							return this.agreementData.children.length > 0
						})
					}
				},
			},
			relationship:{
				status:{
					required
				},
				cohabitationDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				},
				marriageDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status === 'Married'
					})
				},
				marriageLocation:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status === 'Married'
					})
				},
				separationDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				},
				livingTogether:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				}
			}
		}
	}
}
</script>

<style>
#menu {
	position: sticky;
	top: 0.375em;
	max-height: calc(100vh - 136px - 32px);
	overflow: scroll;
	overflow-y: auto !important;
}
@media only screen and (max-width: 872px) {
	#menu {
		max-height: calc(100vh - 168px - 32px);
	}
}

#menu .card-header {
	padding: 0.375em 0.625em;
}

#menu .list-group-item {
	padding: 0.1875em 0.625em 0.2em;
	border: 0;
}
.tab-content > div:not(.no-scroll) {
	overflow-y: scroll;
	overflow-x: hidden;
	/* height: calc(100vh - 129px); */
	max-height: calc(100vh - 180px);
}

* {
  margin: 0;
}

.content-row{
	height: 100vh;
}
.side-bar{
	padding-left: 0%;
	max-width:100%
}
.builder-col, .preview-col, .editor-col{
	max-width:23.125rem;
	background-color: #3F6772;
}
.hideToggleBar{
	max-width:20.125rem;
	padding-left: 20px;
}
.mb-builder-col, .mb-preview-col, .mb-editor-col,
.mb-property-schedule-col, .mb-parental-planner-col, .mb-separation-fact-sheet-col, .mb-summary-col, .mb-financialDocumentChecklist-col{
	background-color: #D5E0E4;
}
.nav-bar{
	max-height: 100vh;
}
.property-schedule-col, .parental-planner-col, .separation-fact-sheet-col, .summary-col, .financialDocumentChecklist-col{
	max-width: 6.5rem;
	background-color: #3F6772;
}
.aside{
	max-width: 4.775rem;
	background-color: #3F6772;
	margin-left: 1.5rem;
	padding-top: 1.5rem;
	padding-right: 1.5rem;
}
#menu{
	max-height: 100vh;
	height: 100vh;
	padding: 0%;
	background-color: #9CB6BD;
	border-right: 3px solid #D5E0E4
;
}
.nav-topics{
	padding: 0%;
}
.bg-color{
	background-color: #9CB6BD;
}
.nav-card{
	border: #DFE4E6;
	padding-top: 0.5rem;
}
.icon, .icon-disabled{
	width: 1.375rem;
	height: 1.375rem;
	margin: 0 !important;
	/* padding-bottom: 2.25rem; */
	/* filter: invert(27%) sepia(83%) saturate(362%) hue-rotate(150deg) brightness(98%) contrast(90%); */
	filter: invert(100%) grayscale(100%) brightness(100%);

}
.icon-disabled{
opacity: 0.3;
}
.img-icon{
 	padding: 0.4375rem;
  	border-radius: 0.5rem;
}
.img-icon:hover, .active{
	background-color: #243B42;
	cursor: pointer;
}
.inactive:hover{
	cursor: not-allowed;
	background-color: #D5E0E4;
}
a{
	color: #243B42;
}
.items:hover{
	padding: 0.4375rem;
  	border-radius: 0.5rem;
	background-color: #3F6772;
	cursor: pointer;
}

.items:hover a{
	color: #ffff;
}
.icons{
	padding-bottom: 1.5rem;
}
a:hover{
	text-decoration: none;
	/* color: #3F6772; */
}
.list-topic{
	color: #243B42;
    font-weight: bold
}
.mb-aside{
	max-width: calc(100vw - 2rem);
    /* margin-left: 0.5rem; */
	border-radius: 10px;
	max-height: 3rem;
	padding:0;
	background-color: #3f6772;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 3rem;
    padding-left: 0.5rem;
	justify-content: space-around;
}
.mobile-view{
	max-height: 3rem;
	background-color: white;
	width: calc(100vw - 0.5rem);
	bottom: 1.5rem;
	position: fixed;
	z-index: 3;
}
.content-row-mobile{
	max-height: calc(100vh - 12.5rem);
}
.mb-mt-2{
	padding-left: 1.563rem;
}
.mobile-nav-bar{
	margin-left: 1.563rem;
    padding-top: 5px;
    padding-bottom: 5px;
}
.currentItem{
	padding: 0.4375rem;
  	border-radius: 0.5rem !important;
	background-color: #3F6772;
}
.currentLink{
	text-decoration: none;
	color: white
;
}
.sidebar-icons{
	position: fixed;
	bottom: 25px;
	margin-left: 7px;
	cursor: pointer;
}
.toggleSideBar{
	max-width: 6.5rem;
}
.toggleIcon{
	/* color: #5b9ebb; */
	font-size: 20px !important;
}

select{
	cursor: pointer;
}

.custom-control-input{
	cursor: pointer;
}

.custom-control-label{
	cursor: pointer;
}
.description{
	padding-bottom: 15px;
}
@media only screen and (min-width: 670px) and (max-width: 821px) {
	.right-property-scheduler{
		max-width: 85%;
	}
}

@media print{
	.hide{
		display: none !important;
	}
	.side-bar{
		position: absolute !important;
	}
}
@media screen {
	.side-bar{
		position: fixed !important;
	}
}
@media (max-width: 760px) {
    .mobile-section{
		padding-bottom: 4.5rem !important;
	}
	.submit-form{
		position: fixed;
		margin-left: 0.7rem;
		bottom: calc(2px + 70px) !important;
		z-index: 10;
	}
	/* .card-body , .card {
		margin-bottom: 2rem !important;
	} */
}

/* .submit-form-outer{
	justify-content:flex-end;
	padding-right: 1rem;
} */

.submit-form{
	float: right;
	margin-right: 1rem;
	margin-top: 0.5rem;
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

#education-panel{
	top: 7.1875rem;
	max-height: calc(100vh - 7.1875rem);
	overflow-y: scroll;
	width: 19rem;
}


.ed-video-modal{
  width: 45.9375rem;
  height: 25.9375rem;
}

.ed-modal-website{
  width: 100%;
  height: 29.9375rem;
}

.ed-image-modal{
  width: 45.9375rem;
  height: 25.9375rem;
}

.ed-pdf-modal{
  width: 100%;
  height: 30.3125rem;
}

@media (max-width: 768px) {
	#education-panel{
		width: 25rem;
	}

	.ed-panel-img {
		width: 15.625rem !important;
	}

	.ed-video-modal{
		width: 100%;
		height: 12.5rem;
	}
	.ed-modal-website{
		width: 100%;
		height: 78vh;
	}

	.ed-image-modal{
		width: 100%;
		height: 12.5rem;
	}

	.ed-pdf-modal{
		width: 100%;
		height: 31.25rem;
	}
}

.ed-panel-name{
	color: #243B42;
}

.ed-panel-img {
  width: 200px;
}

.ed-panel-item {
  transition:  0.3s ease;
}

.ed-panel-item:hover {
  background-color: #9CB6BD;
  border-radius: 10px;
  font-weight: 600;
  font-size: medium;

}

.current-ed-panel-item{
	background-color: #9CB6BD;
	border-radius: 10px;
	font-weight: 600;
	font-size: medium;
}

#education-panel .b-sidebar-body::-webkit-scrollbar-thumb {
	border: 5px solid transparent;
}

.perm-outer{
	background-color: #fff;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.perm-text{
	font-size: 20px;
	text-align: center;
	position: absolute;
	right: 50%;
	bottom: 50%;
	transform: translate(50%,50%);
}

@media (max-width: 1484px) {
	.collaboration-col{
		max-width: 99%;
	}
}
</style>

<template>
	<div>
		<b-container bv-example-row class="side-bar" >
			<b-row class="content-row" v-bind:class="{'content-row-mobile': isMobile}">
				<b-col v-bind:class="{'toggleSideBar': !showSubSections,[activeTab + '-col']: true,'left-col': true,'hide': true}" v-if="!isMobile">					
					<b-row class="nav-bar">
						<b-col class="aside">
							<div v-for="(tab, index) in tabs" :key="tab.id">
							<div class="icons">
								<div class="img-icon" 
								v-on="tab.active==false ? {click: ($event) => $event.preventDefault() } : 
								{ click: () => { tabChanged(index); goToSelectedSection(activeTab); }}"
								v-on:click="selectedIcon()"
								:class="{'active': index == activeIndex, 'inactive': tab.active==false ,'btn-disabled-but-clickable': trialDisabledTabs(tab.id)}"
								v-bind:keys="tab.id">
								<!-- <div class="img-icon" 
								@click="tabChanged(index)"
								v-on:click="selectedIcon()"
								:class="{'active': index == activeIndex, 'inactive': tab.active==false}"
								v-bind:keys="tab.id"> -->
									<img :src=tab.imgSrc class="icon" v-bind:class="{'icon-disabled': tab.active==false}" 
									:id=tab.id :title=tab.title :data-testid="tab.id+'-icon'"/>
								</div>
							</div>
						    </div>
							<div class="sidebar-icons" v-if="['builder', 'preview', 'editor'].includes(activeTab)">
								<b-icon-chevron-bar-right variant="light" @click="showSubSections = !showSubSections" v-if="!showSubSections" class="toggleIcon"></b-icon-chevron-bar-right>
								<b-icon-chevron-bar-left variant="light" @click="showSubSections = !showSubSections" v-if="showSubSections" class="toggleIcon"></b-icon-chevron-bar-left>
							</div>
						</b-col>
						<b-col class="nav-topics" v-if="activeTab=='builder' && showSubSections">
							<div id="menu" class="custom-scrollbar-small" >
								<b-card class="mb-1 mt-0 nav-card bg-color">
									<b-list-group flush class="directory bg-color">
										<b-list-group-item class="bg-color list-topic"
											v-for="group in agreementGroups"
											:key="group.title"
										>
											{{ group.title }}
											<b-list-group class="bg-color pt-1"
												v-for="section in group.sections"
												:key="section.id"
											>
												<b-list-group-item class="bg-color items" :class="{'currentItem' : section.id === currentSection }"  @click="goToBuilderSection(section)">
														<a
														:href="`#${section.id}`"
														:class="{'currentLink' : section.id === currentSection }"
														@click.prevent
														>{{ section.title }}
														</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group>
									<b-button-group
										class="mt-2"
									>
										<b-button
											v-if="isSummaryViewable && agreementData && !trialActiveForClients()"
											variant="btn-primary"
											class="mt-3 btn btn-primary"
											@click="viewSummary"
										>
											View Agreement Summary
										</b-button>
									</b-button-group>
								</b-card>
							</div>
						</b-col>
						<b-col class="nav-topics" v-if="activeTab=='preview'&& showSubSections" >
							<div id="menu" class="custom-scrollbar-small">
								<b-card class="mb-1 nav-card bg-color">
									<b-list-group flush class="directory bg-color" >
										<b-list-group-item
											v-for="group in agreementPreviewGroups"
											:key="group.title"
											class="bg-color list-topic"
										>
											{{ group.title }}
											<b-list-group
												v-for="section in group.sections"
												:key="section.id"
												class="bg-color pt-1"
											>
												<b-list-group-item class="bg-color items" :class="{'currentItem' : `${section.id}-preview` === currentSection }" @click="goToPreviewSection(section)">
													<a :href="`#${section.id}-preview`" :class="{'currentLink' : `${section.id}-preview` === currentSection }" @click.prevent
														>{{ section.title }}</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
						</b-col>
						<b-col class="d-none d-md-block nav-topics" v-if="activeTab=='editor'&& showSubSections">
							<div id="menu" class="custom-scrollbar-small">
								<b-card class="mb-1 nav-card bg-color">
									<b-list-group flush class="directory bg-color">
										<b-list-group-item
											v-for="group in agreementPreviewGroups"
											:key="group.title"
											class="bg-color list-topic"
										>
											{{ group.title }}
											<b-list-group
												v-for="section in group.sections"
												:key="section.id"
												class="bg-color pt-1"
											>
												<b-list-group-item class="bg-color items" :class="{'currentItem' : `${section.id}-preview` === currentSection }" @click="goToPreviewSection(section)">
													<a :href="`#${section.id}-preview`" :class="{'currentLink' : `${section.id}-preview` === currentSection }" @click.prevent
														>{{ section.title }}</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col class="right" :class="{'right-property-scheduler':activeTab=='property-schedule'}">
					<active-user />
					<agreement-navigation @toggleEdPanel="handleToggleEdPanel" :isEdPanelOpen="isEdPanelOpen" :currentRoleLevelName="currentAgreementRoleLevelName" :showPermissionDeniedPageDisplay="showPermissionDeniedPageDisplay"
					:agreementData="agreementData"/>
					<b-tabs
						id="agreement-nav"
						content-class="mt-1 tab-content"
						class="mt-1 bg-white"
						fill
						nav-wrapper-class="print-hide bg-white"
					>
						<!-- NOTE: BELOW LINES COMMENTED AS PART OF https://app.asana.com/0/1201747891973200/1203709457871617/f to hide summary tabs -->
						<!-- <b-tab
							v-if="featureEnabled('feature_summary')"
							id="summary-tab"
							title="Summary"
							:active="activeTab === 'summary'"
						>
							<summary-view />
						</b-tab> -->
								<b-row v-if="activeTab=='builder'" ref="scrollable" @scroll="handleAgreementScroll" class='custom-scroll' v-bind:class="{'scroll-small mobile-section': isMobile}">
									<b-col class="mt-2" v-bind:class="{'mb-mt-2':isMobile}">
										<b-alert variant="warning" :show="editorEnabled">
											<h5 class="alert-heading">Heads Up!</h5>
											<div>
												Any changes made here will not be reflected
												in your working document in the Editor. This
												includes values that you will see have been
												updated in the Property Schedule.
												<b
													>Values will need to be manually updated
													in your working document.</b
												>
											</div>
										</b-alert>
										<div class="description">
											<b-form-textarea
												v-model="agreementData.description"
												placeholder="Add a description"
												rows="3"
												max-rows="1"
												style="width: 100%"
												no-resize
												trim
												data-testid="agreement-description"
												maxlength="60"
											/>
										</div>
										<div v-if="showAgreementTrialExpired" class="d-flex justify-content-center mb-3 h-100">
											<div class="perm-outer">
												<b-alert show variant="warning" class="perm-text">
													<h5 class="alert-heading">You can't access this agreement</h5>
													<div>
														Your access to the agreement has ended. Please subscribe or contact the law firm to regain access.
													</div>
													<router-link :to="{ name: 'subscription' }" tag="b-button" class="btn-primary btn-lg mt-3 col-12">
														Subscribe
													</router-link>
												</b-alert>
											</div>
										</div>
										<div v-else-if="isSubscriptionCancelled" class="d-flex justify-content-center mb-3 h-100">
											<div class="perm-outer">
												<b-alert show variant="warning" class="perm-text">
													<h5 class="alert-heading">You can't access this agreement</h5>
													<div>
														Your subscription has been cancelled. Resubscribe to regain access and continue enjoying our services.
														<p v-if="isDownloadAgreementAllowed" class="mb-0">
															Alternatively, you can
															<b-link
																target="_blank"
																:to="{
																	name: 'preview',
																	params: { id: this.agreement.id },
																}"
																>download</b-link
															>
															your file now.	
														</p>
													</div>
													<router-link :to="{ name: 'subscription' }" tag="b-button" class="btn-primary btn-lg mt-3 col-12">
														Subscribe
													</router-link>
												</b-alert>
											</div>
										</div>
										<div v-else-if="isSubscriptionCancelled" class="d-flex justify-content-center mb-3 h-100">
											<div class="perm-outer">
												<b-alert show variant="warning" class="perm-text">
													<h5 class="alert-heading">You can't access this agreement</h5>
													<div>
														Your subscription has been cancelled. Resubscribe to regain access and continue enjoying our services.
														<p v-if="isDownloadAgreementAllowed" class="mb-0">
															Alternatively, you can
															<b-link
																target="_blank"
																:to="{
																	name: 'preview',
																	params: { id: this.agreement.id },
																}"
																>download</b-link
															>
															your file now.	
														</p>
													</div>
													<router-link :to="{ name: 'subscription' }" tag="b-button" class="btn-primary btn-lg mt-3 col-12">
														Subscribe
													</router-link>
												</b-alert>
											</div>
										</div>
										<div
											v-else-if="!agreementData || showPermissionDeniedPageDisplay"
											class="d-flex justify-content-center mb-3 h-100"
										>
											<permission-error v-if="showPermissionDeniedPageDisplay"></permission-error>
											<b-card class="w-100">
												<h4 slot="header">
													<b-skeleton
														width="25%"
														animation="wave"
													></b-skeleton>
												</h4>
												<div class="mb-5">
													<b-skeleton
														width="85%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="55%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="70%"
														animation="wave"
													></b-skeleton>
												</div>
												<div class="mb-5">
													<b-skeleton
														width="85%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="55%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="70%"
														animation="wave"
													></b-skeleton>
												</div>
												<div class="mb-5">
													<b-skeleton
														width="85%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="55%"
														animation="wave"
													></b-skeleton>
													<b-skeleton
														width="70%"
														animation="wave"
													></b-skeleton>
												</div>
											</b-card>
										</div>
										<component
											:is="section.component"
											v-for="section in agreementSections"
											v-else
											:id="section.id"
											:key="section.id"
											class="mb-4 mt-0 sections"
											:agreement-data="agreementData"
											:title="section.title"
											v-bind="section.props"
											:ref="section.id"
										/>
									</b-col>
								</b-row>
								<!-- {{ activeTab }}-{{ currentSection }}--{{ lastSection }}=={{ isClientUserAgreementComputed }} -->
								<template v-if="activeTab == 'builder' && isEndOfAgreementPosition && isClientUserAgreementComputed" class="submit-form-outer">
									<b-button variant="navy" class="submit-form" @click="submitFormModal" :disabled="isSubmitFormDisabled" data-testid="submit-client-form-btn">
										<template v-if="isSubmitFormDisabled">
											<b-spinner small type="grow"></b-spinner>
											Submitting...
										</template>
										<template v-else>
											Submit to lawyer
										</template>
									</b-button>
								</template>

							<template  v-if="activeTab === 'parental-planner'">
								<div v-if="!agreementData">
									<loader />
								</div>
								<parental-planner :tabChangeToBuilder="tabChanged" class="mobile-section"/>
							</template>

							<template  v-if="activeTab === 'property-schedule'">
								<loader v-if="!agreementData" />
								<property-schedule :agreementData="agreementData" v-else class="no-scroll mobile-section" />
							</template>
							<template v-if="activeTab === 'separation-fact-sheet'">
								<b-row  class='custom-scroll' v-bind:class="{'scroll-small': isMobile}"> 
									<b-col cols>
										<loader v-if="!agreementData" />
									<separation-fact-sheet v-else :agreementData="agreementData"></separation-fact-sheet>
									</b-col>
								</b-row>
							</template>
							<b-row v-if="activeTab === 'summary'" class='custom-scroll' v-bind:class="{'scroll-small': isMobile}"> 
								<b-col cols>
									<loader v-if="!agreementData" />
									<new-summary @openEditor="openEditor" :agreementData="agreementData" v-else />
								</b-col>
							</b-row>
							<b-row  v-if="activeTab === 'summary' && summaryDisabled">{{viewSummary()}}</b-row>

								<b-row v-if="activeTab === 'preview'" class='custom-scroll' v-bind:class="{'scroll-small mobile-section': isMobile}"> 
									<b-col cols>
										<loader v-if="!agreementData" />
										<preview-wrapper :agreementData="agreementData" v-else />
									</b-col>
								</b-row>
								<template>
								<b-row v-if="activeTab === 'editor' && !editorDisabled" class='no-scroll' >
									<b-col cols class="collaboration-col">
										<loader v-if="!agreementData" />
										<collaboration :agreementData="agreementData" v-else />
									</b-col>
								</b-row>
								<b-row v-if="activeTab === 'editor' && editorDisabled">{{edit()}}</b-row>
							</template>
							<template v-if="activeTab === 'financialDocumentChecklist'">
								<b-row  class='custom-scroll' v-bind:class="{'scroll-small': isMobile}"> 
									<b-col cols>
										<loader v-if="!agreementData" />
										<financial-document-checklist v-else  :agreementData="agreementData"></financial-document-checklist>
									</b-col>
								</b-row>
							</template>
							<!-- <b-tab
								v-if="featureEnabled('feature_documents')"
								id="documents-tab"
								title="Documents"
								lazy
								:active="activeTab === 'documents'"
							>
								<documents />
							</b-tab> -->
							<!-- <b-tab
								v-if="featureEnabled('feature_childSupport')"
								id="childSupportCalculations-tab"
								title="Support"
								lazy
								:active="activeTab === 'childSupport'"
							>
								<child-support-calculations />
							</b-tab> -->
					</b-tabs>
				</b-col>
				<b-sidebar right shadow id="education-panel"  v-model="isEdPanelOpen" class="education-panel" v-if="(currentAgreementRoleLevelName === 'client-level-1' ||currentAgreementRoleLevelName === 'client-level-2') && !showPermissionDeniedPageDisplay && !trialActiveForClients() && !isClientUserAgreementComputed && !showAgreementTrialExpired">
					<div class="p-3 pt-0 pb-0">
						<div v-for="(item, index) in educationPanelData" :key="index" :id="item.redirected" :class="{'current-ed-panel-item' : activeTab === 'builder'?  item.redirected === currentSection :item.redirected === activeTab  }" class="clickable ed-panel-item p-2 pt-3 mb-5" @click="showEdModal(item)"
						:data-testid="'open-education-panel-item-'+index">
							<div v-if="item.type === 'video'" class="d-flex flex-column align-items-center">
								<img class="ed-panel-img"  :src="getVideoThumbnail(item.url)" alt="Video Thumbnail">
								<p class="mt-2 ed-panel-name mb-0 pb-0">{{ item.name }}</p>
							</div>
							<div v-else-if="item.type === 'image'" class="d-flex flex-column align-items-center">
								<img  class="ed-panel-img" :src="item.url" alt="Image">
								<p class="mt-2 ed-panel-name mb-0">{{ item.name }}</p>
							</div>
							<div v-else-if="item.type === 'document'" class="d-flex flex-column align-items-center">
								<b-icon-file-earmark-text  font-scale="5"></b-icon-file-earmark-text>
								<p class="mt-2 ed-panel-name mb-0">{{ item.name }}</p>
							</div>
							<div v-else-if="item.type === 'website'" class="d-flex flex-column align-items-center">
								<b-icon-file-earmark-text  font-scale="5"></b-icon-file-earmark-text>
								<p class="mt-2 ed-panel-name mb-0">{{ item.name }}</p>
							</div>
						</div>
					</div>
				</b-sidebar>
			</b-row>
			<template>
			<b-row v-bind:class="'mb-'+activeTab+'-col mobile-view'" v-if="isMobile">				
					<b-col class="mobile-nav-bar">
						<b-row class="mb-aside">
							<div v-for="(tab, index) in tabs" v-bind:key="tab.id">
							<div class="icons">
								<div class="img-icon" 
								v-on="tab.active==false ? {click: ($event) => $event.preventDefault() } : 
								{ click: () => tabChanged(index) }"
								v-on:click="selectedIcon()"
								:class="{'active': index == activeIndex, 'inactive': tab.active==false}"
								v-bind:keys="tab.id">
								<!-- <div class="img-icon" 
								v-on:click="selectedIcon()"
								:class="{'active': index == activeIndex}" @click="tabChanged(index)"
								> -->
									<img :src=tab.imgSrc class="icon" v-bind:class="{'icon-disabled': tab.active==false}" 
										:id=tab.id :title=tab.title />
									<!-- <img :src=tab.imgSrc class="icon" :id=tab.id :title=tab.title @click="tabChanged(index)"/> -->
								</div>
							</div>
						    </div>
						</b-row>
					</b-col>
				</b-row>
				</template>

				<b-modal ref="ed-modal" :size="isMobile? 'xl':'lg'" v-if="!trialActiveForClients()">
					<template #modal-header="{ close }">
						<button type="button" class="close" @click="close" aria-label="Close" data-test-id="education-modal-close-button">
							<span aria-hidden="true">&times;</span>
						</button>
					</template>
					<div v-if="edModalData.type === 'video'" class="d-flex flex-column align-items-center">
						<iframe class="ed-video-modal" 
							:src="`https://www.youtube.com/embed/${edModalData.videoId}`">
						</iframe>
					</div>
					<div v-else-if="edModalData.type === 'website'" class="d-flex flex-column align-items-center">
						<iframe class="ed-modal-website" 
							:src="edModalData.url">
						</iframe>
					</div>
					<div v-else-if="edModalData.type === 'image'" class="d-flex flex-column align-items-center">
						<img class="ed-image-modal" :src="edModalData.url" alt="Image">
					</div>
					<div v-else-if="edModalData.type === 'document'" class="d-flex flex-column align-items-center">
						<object class="ed-pdf-modal" :data="edModalData.url" type="application/pdf"></object>
					</div>
					<template v-slot:modal-footer>
						<b-button variant="primary" v-if="edModalData.isDownloadAllowed" @click="downloadEdFile(edModalData.url ,edModalData.type)" 
							data-testid="download-education-panel-item">Download &nbsp;<b-icon-download/></b-button>
						<b-button variant="primary" @click="redirectToComponent(edModalData.redirected)"
							data-testid="go-to-education-panel-section">Go to this section &nbsp;<b-icon-arrow-right/></b-button>
						</template>
				</b-modal>
			<!-- <active-user />
			<agreement-navigation /> -->

			<!-- <b-tabs
				id="agreement-nav"
				content-class="mt-1 tab-content"
				class="mt-1 bg-white"
				fill
				nav-wrapper-class="print-hide bg-white"
				@activate-tab="tabChanged"
				lazy
			>
				<b-tab
					v-if="featureEnabled('feature_summary')"
					id="summary-tab"
					title="Summary"
					:active="activeTab === 'summary'"
				>
					<summary-view />
				</b-tab>
				<b-tab id="builder-tab" title="Builder" data-testid="builder">
					<b-row>
						<b-col cols="3" class="d-none d-sm-block">
							<div id="menu">
								<b-card class="mb-1 mt-0">
									<h4 slot="header" class="mt-2 ml-2">Agreement Topics</h4>
									<b-list-group flush class="directory">
										<b-list-group-item
											v-for="group in agreementGroups"
											:key="group.title"
										>
											{{ group.title }}
											<b-list-group
												v-for="section in group.sections"
												:key="section.id"
											>
												<b-list-group-item>
													<a
														:href="`#${section.id}`"
														>{{ section.title }}</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group>
									<b-button-group
										v-if="editorDisabled"
										class="mt-2"
									>
										<b-button
											variant="btn-primary"
											class="mt-3 btn btn-primary"
											@click="edit"
											data-testid="edit-agreement-button"
										>
											Edit Agreement
										</b-button>
									</b-button-group>
								</b-card>
							</div>
						</b-col>
						<b-col class="mt-2">
							<b-alert variant="warning" :show="editorEnabled">
								<h5 class="alert-heading">Heads Up!</h5>
								<div>
									Any changes made here will not be reflected
									in your working document in the Editor. This
									includes values that you will see have been
									updated in the Property Schedule.
									<b
										>Values will need to be manually updated
										in your working document.</b
									>
								</div>
							</b-alert>

							<div
								v-if="!agreementData"
								class="d-flex justify-content-center mb-3 h-100"
							>
								<b-card class="w-100">
									<h4 slot="header">
										<b-skeleton
											width="25%"
											animation="wave"
										></b-skeleton>
									</h4>
									<div class="mb-5">
										<b-skeleton
											width="85%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="55%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="70%"
											animation="wave"
										></b-skeleton>
									</div>
									<div class="mb-5">
										<b-skeleton
											width="85%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="55%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="70%"
											animation="wave"
										></b-skeleton>
									</div>
									<div class="mb-5">
										<b-skeleton
											width="85%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="55%"
											animation="wave"
										></b-skeleton>
										<b-skeleton
											width="70%"
											animation="wave"
										></b-skeleton>
									</div>
								</b-card>
							</div>
							<component
								:is="section.component"
								v-for="section in agreementSections"
								v-else
								:id="section.id"
								:key="section.id"
								class="mb-4 mt-0"
								:agreement-data="agreementData"
								:title="section.title"
								v-bind="section.props"
							/>
						</b-col>
					</b-row>
				</b-tab>

				<b-tab
					v-if="sectionEnabled('Parenting')"
					title="Parenting Time Planner"
					lazy
					:active="activeTab === 'parental-planner'"
				>
					<div v-if="!agreementData">
						<loader />
					</div>
					<parental-planner />
				</b-tab>

				<b-tab
					v-if="sectionEnabled('Property')"
					id="property-schedule-tab"
					title="Property Schedule"
					lazy
					class="no-scroll"
					:active="activeTab === 'property-schedule'"
				>
					<loader v-if="!agreementData" />
					<property-schedule :agreementData="agreementData" v-else />
				</b-tab>
				<b-tab
					id="preview-tab"
					title="Preview"
					lazy
					:active="activeTab === 'preview'"
				>
					<b-row>
						<b-col
							cols="3"
							md="3"
							class="print-hide d-none d-sm-block"
						>
							<div id="menu">
								<b-card class="mb-1">
									<h4 slot="header" class="mt-2 ml-2">Agreement Topics</h4>
									<b-list-group flush class="directory">
										<b-list-group-item
											v-for="group in agreementPreviewGroups"
											:key="group.title"
										>
											{{ group.title }}
											<b-list-group
												v-for="section in group.sections"
												:key="section.id"
											>
												<b-list-group-item>
													<a
														:href="`#${section.id}-preview`"
														>{{ section.title }}</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group> -->
									<!-- <b-button-group
										v-if="editorDisabled"
										class="mt-2"
									>
										<b-button
											variant="btn-primary"
											class="mt-3 btn btn-primary"
											@click="edit"
										>
											Edit Agreement
										</b-button>
									</b-button-group> -->
								<!-- </b-card>
							</div>
						</b-col>
						<b-col cols="9" md="8">
							<loader v-if="!agreementData" />
							<preview-wrapper v-else />
						</b-col>
					</b-row>
				</b-tab>
				<b-tab
					id="editor-tab"
					title="Editor"
					lazy
					:disabled="editorDisabled"
					:active="activeTab === 'editor'"
				>
					<b-row>
						<b-col cols="3" class="d-none d-md-block">
							<div id="menu">
								<b-card class="mb-1">
									<h4 slot="header" class="mt-2 ml-2">Agreement Topics</h4>
									<b-list-group flush class="directory">
										<b-list-group-item
											v-for="group in agreementPreviewGroups"
											:key="group.title"
										>
											{{ group.title }}
											<b-list-group
												v-for="section in group.sections"
												:key="section.id"
											>
												<b-list-group-item>
													<a
														:href="`#${section.id}-preview`"
														>{{ section.title }}</a
													>
												</b-list-group-item>
											</b-list-group>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
						</b-col>
						<b-col cols>
							<loader v-if="!agreementData" />
							<collaboration v-else />
						</b-col>
					</b-row>
				</b-tab>
				<b-tab
					v-if="featureEnabled('feature_documents')"
					id="documents-tab"
					title="Documents"
					lazy
					:active="activeTab === 'documents'"
				>
					<documents />
				</b-tab>
				<b-tab
					v-if="featureEnabled('feature_childSupport')"
					id="childSupportCalculations-tab"
					title="Support"
					lazy
					:active="activeTab === 'childSupport'"
				>
					<child-support-calculations />
				</b-tab>
				</b-tabs> -->		
		</b-container>
		<subscription-modal :showSubscriptionModal="showSubscriptionModal" @close="showSubscriptionModal = false"></subscription-modal>
	</div>
</template>
